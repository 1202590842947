// menuUtils.js

export function buildMenuBasedOnRole(role) {
    const baseMenu = [
        {
            label: 'Home',
            items: [
                {label: 'Landing Page', icon: 'pi pi-fw pi-home', to: '/landingpage'},
                {label: 'Mein Profil', icon: 'pi pi-fw pi-th-large', to: '/profileinformation'},
            ]
        },
    ];

    const adminAdditionalMenu = [
        {
            label: 'Stammdaten',
            items: [
                {label: 'Personalstamm', icon: 'pi pi-fw pi-users', to: '/employeetable'},
                {label: 'Abwesenheitstypen', icon: 'pi pi-fw pi-sign-out', to: '/absencetypeadder'},
                {label: 'Allgemein Informationen', icon: 'pi pi-fw pi-list', to: '/additionalfieldsadder'},
                {label: 'Arbeitszeitmodelle', icon: 'pi pi-fw pi-th-large', to: '/shiftrhythmadder'},
                {label: 'Urlaubsübertragsmodelle', icon: 'pi pi-fw pi-file', to: '/carryovermodelsconfig'},
                {label: 'Überstundenmodelle', icon: 'pi pi-fw pi-money-bill', to: '/overtimeconfig'},
                {label: 'Genehmigungsmodelle', icon: 'pi pi-fw pi-question', to: '/requestapprovalmodels'},
                
            ]
        },
        {
            label: 'Services',
            icon: 'pi pi-fw pi-search',
            items: [
                {label: 'Abwesenheitsplanung', icon: 'pi pi-fw pi-calendar', to: '/holidayplanner'},
                // {label: 'Zeiterfassung', icon: 'pi pi-fw pi-clock', to: '/employeetimetracking'},
                {label: 'Anwesenheitsprüfung', icon: 'pi pi-fw pi-sign-in', to: '/attendancecheck'},
            ]
        }
    ];

    const adminDashboard = {
        label: 'Firmen Dashboard', 
        icon: 'pi pi-fw pi-chart-bar',
        to: '/dashboardcompanyeview'
        // items: [
        //     {
        //         label: 'Firmenansicht', 
        //         icon: 'pi pi-fw pi-sitemap',
        //         to: '/dashboardcompanyeview'
        //     },
        //     {
        //         label: 'Mitarbeiteransicht', 
        //         icon: 'pi pi-fw pi-user',
        //         to: '/dashboardemployeeview'
        //     }
        // ]
        // to: '/admindashboard'};
    }

    if (role === 'admin') {
        baseMenu[0].items.push(adminDashboard)
    }

    return role === 'admin' ? [...baseMenu, ...adminAdditionalMenu] : [...baseMenu];
}
