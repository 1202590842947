export default class ApiClient {
    constructor(baseURL) {
        this.baseURL = baseURL;
        this.headers = {
            "Content-Type": "application/json",
        };
    }
    
    setAuthToken(token) {
        this.headers['Authorization'] = `Bearer ${token}`;
    }

    getAuthToken() {
        // Your logic here to get token, maybe from localStorage or a cookie.
    }

    async refreshToken() {
        // Your logic to refresh the token and update headers
    }

    async getRequest(endpoint) {
        return this.sendRequest(endpoint, "GET");
    }

    async postRequest(endpoint, body) {
        return this.sendRequest(endpoint, "POST", body);
    }

    async putRequest(endpoint, body) {
        return this.sendRequest(endpoint, "PUT", body);
    }

    async deleteRequest(endpoint) {
        return this.sendRequest(endpoint, "DELETE");
    }

    async sendRequest(endpoint, method, body = null) {
        const url = `${this.baseURL}${endpoint}`;

        const options = {
            method,
            headers: this.headers,
            ...(body && { body: JSON.stringify(body) }),
        };

        try {
            let response = await fetch(url, options);
            if (response.status === 403) {
                await this.refreshToken();
                options.headers.Authorization = this.headers.Authorization;
                response = await fetch(url, options);
            }
            if (response.ok) {
                const data = await response.json();
                return data;
            } else {
                const errorDetails = await response.json();
                throw new Error('Server responded with a 422 error: ' + JSON.stringify(errorDetails));
                // throw new Error('Server responded with an error');
            }
        } catch (error) {
            console.error(`Request to ${url} failed: ${error.message}`);
        }
    }
}